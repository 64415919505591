import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, NavLink } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getOrganizationsContests, 
  createContest,
  updateContest,
} from "../../redux/actions/authAction";

const ManageContests = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contestId, setContestId] = useState("");
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newStartDate, setNewStartDate] = useState(new Date());
  const [newEndDate, setNewEndDate] = useState(new Date());
  const [allContests, setAllContests] = useState([]);

  const [show, setShow] = useState(false);
  const [editContestModal, setEditContestModal] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const orgId = location.state?.orgData?._id;

  const { allOrgContests } = useSelector((state) => state.getContestData);

  useEffect(() => {
    dispatch(getOrganizationsContests({ organizationId: orgId }));
  }, [orgId, dispatch]); 
  
  useEffect(() => {
    setAllContests(allOrgContests);
  }, [allOrgContests, allContests]); 
  

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const onSaveNewContestData = async () => {
    const newContestData = {
      name: newName,
      description: newDescription,
      startDate: newStartDate,
      endDate: newEndDate,
      organizationId: orgId,
    };
  
    try {
      const createdContest = await dispatch(createContest(newContestData));
      if (createdContest) {
        setAllContests((prevContests) => [...prevContests, createdContest]);
      }
    } catch (error) {

    }
    
    console.log(createContest)
    dispatch(getOrganizationsContests({ organizationId: orgId }));
    handleClose();
    setNewName("");
    setNewDescription("");
    setNewStartDate(new Date());
    setNewEndDate(new Date());
  };

  const editContestModalClose = () => {
    setEditContestModal(false);
  };
  const editContestModalShow = (data) => {
    setEditContestModal(true);
    setName(data?.name);
    setDescription(data?.description);
    setStartDate(new Date(data?.startDate));
    setEndDate(new Date(data?.endDate));
    setContestId(data?._id);
  };


  const editContestDetailsHandler = (data) => {
    const contestUpdatedData = {
      name: name,
      description: description,
      startDate: startDate,
      endDate: endDate,
      contestId: contestId,
    };
    
    dispatch(updateContest(contestUpdatedData));
    editContestModalClose()
  };

  const formatDate = (dateString) => {
    const createdAt = new Date(dateString);
    const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
    // const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
  
    return `${createdAt.toLocaleDateString('en-US', optionsDate)}`;
  };

  return (
    <>
      <>
        {/* ADD NEW CONTEST MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Create new Contest</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Contest name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="name"
                      onChange={(e) => setNewName(e.target.value)}
                      value={newName}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="description"
                      onChange={(e) => setNewDescription(e.target.value)}
                      value={newDescription}
                    />
                  </Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <DatePicker
                      onChange={setNewStartDate}
                      value={newStartDate}
                    />
                  </Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <DatePicker
                      onChange={setNewEndDate}
                      value={newEndDate}
                    />
                  </Form.Group>
                </Form>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => onSaveNewContestData(e)}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {/* EDIT CONTEST MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={editContestModal}
            onHide={editContestModalClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Edit Contest</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </Form.Group>
                <Form.Label>Start Date</Form.Label>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <DatePicker
                      onChange={setStartDate}
                      value={startDate}
                    />
                  </Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <DatePicker
                    onChange={setEndDate}
                    value={endDate}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={editContestModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={editContestDetailsHandler}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        <>
          <div className="manage-user">
            <div className="row mb-3 pt-5 pl-5">
              <div className="col-md-6">
                <h1 className="healthKitUpdateHeaderFont d-flex">
                  Manage Contests
                </h1>
              </div>
              <div
                className="col-md-6 d-flex justify-content-end"
                style={{ paddingRight: "65px" }}
              >
                <div>
                  <button className="btn-coach me-4" onClick={handleShow}>
                    Create new contest
                  </button>
                </div>
              </div>
            </div>
            <>
              <div
                className="coach-clients-page "
                style={{ padding: "2% 3% 0% 3%" }}
              >
                <>
                  <div className="clients-page-main-container">
                    {
                      allContests.length ? (
                        <>
                          <table>
                            <thead>
                              <tr
                                className="header-row"
                                style={{ margin: "10px", height: "50px" }}
                              >
                                <th
                                  style={{
                                    width: "350px",
                                    paddingLeft: "26px",
                                    textAlign: "left",
                                  }}
                                >
                                  Contest name
                                </th>
                                <th
                                  style={{
                                    width: "300px",
                                    textAlign: "left",
                                  }}
                                >
                                  Description
                                </th>
                                <th
                                  style={{
                                    width: "275px",
                                    textAlign: "left",
                                  }}
                                >
                                  Start Date
                                </th>
                                <th
                                  style={{
                                    width: "150px",
                                    textAlign: "left",
                                  }}
                                >
                                  End Date
                                </th>
                                <th
                                  style={{
                                    width: "150px",
                                    textAlign: "left",
                                  }}
                                >
                                  Is Active Contest
                                </th>
                                <th
                                  style={{
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                  }}
                                ></th>
                              </tr>
                            </thead>

                            <tbody>
                              {allContests?.map((data, index) => {
                                return (
                                  <tr
                                    className="whole-row-client"
                                    key={index}
                                    style={{
                                      borderBottom: "1px solid #f1f5f9",
                                    }}
                                  >
                                    <td className="pic-name-in-table">
                                      <div style={{ paddingTop: "15px" }}>
                                        {data?.name}
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        className="d-grid gap-2 d-md-block"
                                        style={{ textAlign: "left" }}
                                      >
                                        {data?.description}
                                      </div>
                                    </td>

                                    <td
                                      style={{
                                        paddingLeft: "12px",
                                        color: "#1b46b4",
                                        textAlign: "left",
                                      }}
                                    >
                                      {formatDate(data?.startDate)}
                                    </td>

                                    <td
                                      style={{
                                        paddingLeft: "12px",
                                        color: "#1b46b4",
                                        textAlign: "left",
                                      }}
                                    >
                                      {formatDate(data?.endDate)}
                                    </td>

                                    <td
                                      style={{
                                        paddingLeft: "12px",
                                        color: "#1b46b4",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data?.isActiveContest.toString()}
                                    </td>

                                    <td
                                      style={{
                                        width: "133px",
                                      }}
                                    >
                                      <div className="clients-data-btn">
                                        <Dropdown>
                                          <Dropdown.Toggle id="toggle-btn-clients">
                                            <img
                                              src="/img/client-detail-btn.png"
                                              alt="btn-img"
                                              style={{
                                                backgroundColor: "white",
                                              }}
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dropdown-options-container">
                                            <Dropdown.Item className="dropdown-options">
                                              <NavLink
                                                className="create-activity-font"
                                                onClick={() => {
                                                  editContestModalShow(data);
                                                }}
                                              >
                                                Edit
                                              </NavLink>
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item className="dropdown-options">
                                              <NavLink
                                                className="create-activity-font"
                                                to="/contest-participants"
                                                onClick={() => {
                                                  editContestModalShow(data);
                                                }}
                                              >
                                                See Participants
                                              </NavLink>
                                            </Dropdown.Item> */}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}{" "}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <div className="No-data-statement">
                          {" "}
                          <h3>No contests yet</h3>
                        </div>
                      )
                    }
                  </div>
                </>
              </div>
            </>
          </div>
        </>
      </>
      <ToastContainer />
    </>
  );
};

export default ManageContests;
