import * as actionTypes from "../actions/actionType";

const initialState = {
  allContests: [],
  allOrgContests: [],
  contestData: [],
  token: "",
  journeysData: null,
};

export const manageContestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CONTESTS:
      return {
        ...state,
        allContests: action.payload,
      };
    case actionTypes.POST_CONTEST:
      return {
        ...state,
        contestData: action.payload,
      };
    case actionTypes.GET_ORGANIZATIONS_CONTESTS:
      return {
        ...state,
        allOrgContests: action.payload,
      };
    case actionTypes.PATCH_CONTEST:
      return {
        ...state,
        contestData: action.payload,
      };
    default:
      return state;
  }
};
