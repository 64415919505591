import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, NavLink } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  assignCoach,
  blockUser,
  getFilteredUsers,
  getBlockListOfUsers,
  unBlockUser,
  updateUser,
  getAllOrganizations,
  getSurveyResultOfUser,
  recoverStreak
} from "../../redux/actions/authAction";

const ManageUsers = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [organizationFilter, setOrganizationFilter] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [userIdToUpdateUser, setUserIdToUpdateUser] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [showAssignCoachModal, setShowAssignCoachModal] = useState(false);
  const [showAssignOrganizationModal, setShowAssignOrganizationModal] =
    useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [blockUserModal, setBlockUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [resState, setResState] = useState(false);
  const [surveyResultModal, setSurveyResultModal] = useState(false);
  const [selectedUserToAssignCoach, setSelectedUserToAssignCoach] =
    useState("");
  const [
    selectedUserToAssignOrganization,
    setSelectedUserToAssignOrganization,
  ] = useState("");
  const [selectedCoachState, setSelectedCoachState] = useState("");
  const [selectedOrganizationState, setSelectedOrganizationState] =
    useState("");
  const [newMob, setNewMob] = useState("");
  const [surveyResult, setSurveyResult] = useState([]);
  const dispatch = useDispatch();
  const { allCoaches } = useSelector((state) => state.getManageCoachesData);
  const { allOrganizations } = useSelector(
    (state) => state.getOrganizationData
  );
  const { filteredUsers, blockUsersList, surveyResultOfSingleUser } =
    useSelector((state) => state.getManageUsersData);
  useEffect(() => {
    dispatch(getFilteredUsers({ organizationId: organizationFilter }));

    dispatch(getAllOrganizations());
  }, [organizationFilter]);

  useEffect(() => {
    if (newPhoneNumber.startsWith("92")) {
      setNewMob("+" + newPhoneNumber);
    } else {
      setNewMob("+1" + newPhoneNumber);
    }
  }, [newPhoneNumber]);

  useEffect(() => {
    dispatch(getBlockListOfUsers());
  }, [resState]);

  const assignCoachHandleClose = () => {
    setShowAssignCoachModal(false);
  };
  const assignOrganizationHandleClose = () => {
    setShowAssignOrganizationModal(false);
    setSelectedOrganizationState("");
  };
  const onAssignCoach = (user) => {
    setShowAssignCoachModal(true);

    setSelectedUserToAssignCoach(user);
  };

  const recoverUsersStreak = (user) => {
    dispatch(recoverStreak({userId: user._id}, callbackForRecoverStreak));
  };
  const callbackForRecoverStreak = (user) => {
    // setShowAssignOrganizationModal(true);

  };

  const onAssignOrganization = (user) => {
    setShowAssignOrganizationModal(true);

    setSelectedUserToAssignOrganization(user);
  };
  const onAssignCoachHandler = () => {
    const objToAssignCoach = {
      userId: selectedUserToAssignCoach?._id,
      coachId: selectedCoachState,
    };
    setShowAssignCoachModal(false);

    dispatch(assignCoach(objToAssignCoach, callback));
  };

  const onAssignOrganizationHandler = () => {
    setShowAssignOrganizationModal(false);
    dispatch(
      updateUser(
        selectedUserToAssignOrganization?._id,
        {
          parentOrganization: selectedOrganizationState,
        },
        callbackForUpdateUser
      )
    );
  };
  const callback = () => {
    dispatch(getFilteredUsers({ organizationId: organizationFilter }));
    setResState(true);
  };
  const onAddUserhandler = () => {
    // alert("@!3");
    const newUserData = {
      firstName: newFirstName,
      lastName: newLastName,
      email: newEmail,
      phoneNumber: newMob,
      isCoach: false,
      isVerified: true,
    };
    dispatch(addUser(newUserData, callbackForAddUser));
  };
  const callbackForAddUser = () => {
    dispatch(getFilteredUsers({ organizationId: organizationFilter }));
    onAddUserModalClose();
    setNewEmail("");
    setNewPhoneNumber("");
    setNewLastName("");
    setNewFirstName("");
  };
  const onAddUserModalClose = () => {
    setAddUserModal(false);
  };
  const onAddUserModalShow = () => {
    setAddUserModal(true);
  };

  const onEditUserModalClose = () => {
    setEditUserModal(false);
  };
  const onEditUserModalShow = (data) => {
    setEditUserModal(true);
    setUserIdToUpdateUser(data?._id);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setPhoneNumber(data?.phoneNumber);
    setEmail(data?.email);
  };
  const onEditUserhandler = () => {
    const userUpdatedData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
    };
    dispatch(
      updateUser(userIdToUpdateUser, userUpdatedData, callbackForUpdateUser)
    );
  };
  const callbackForUpdateUser = () => {
    dispatch(getFilteredUsers({ organizationId: organizationFilter }));
    onEditUserModalClose();
  };
  const onBlockUserModalClose = () => {
    setBlockUserModal(false);
  };
  const onBlockUserModalShow = () => {
    setBlockUserModal(true);
  };
  const blockUserHandler = (data) => {
    dispatch(blockUser(data?._id, callbackForBlockUser));

    setResState(true);
  };
  const callbackForBlockUser = () => {
    dispatch(getFilteredUsers({ organizationId: organizationFilter }));

    setResState(!resState);
  };
  const unBlockUserhandler = (data) => {
    dispatch(unBlockUser(data._id, callbackForUnBlockUser));
  };
  const callbackForUnBlockUser = () => {
    setResState(!resState);
    dispatch(getFilteredUsers({ organizationId: organizationFilter }));
    onBlockUserModalClose();
  };
  const onShowSurveyResult = (userId) => {
    dispatch(getSurveyResultOfUser(userId, callbackForSurveyResult));
  };
  const callbackForSurveyResult = () => {
    setSurveyResultModal(true);
    setSurveyResult(surveyResultOfSingleUser);
  };
  const onSurveyResultModalClose = () => {
    setSurveyResultModal(false);
  };
  return (
    <>
      <>
        {/* SURVEY RESULT MODAL */}
        <>
          <Modal
            size="lg"
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={surveyResultModal}
            onHide={onSurveyResultModalClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Survey Result</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="survey-result-container">
                {surveyResult?.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div key={index}>
                        <div className="survey-question">
                          <p>
                            <span>Question:</span>{" "}
                            <strong>
                              {data?.question_info[0]?.questionText}
                            </strong>
                          </p>
                        </div>
                        <div className="survey-options">
                          Answer:{" "}
                          <span>
                            {data?.attemptQuestions?.selectedOption?.[0] ||
                              "N/A"}
                          </span>
                        </div>
                      </div>
                      <hr style={{ width: "98%" }} />
                    </React.Fragment>
                  );
                })}
              </div>
            </Modal.Body>
          </Modal>
        </>
        {/* ASSIGN COACH MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={showAssignCoachModal}
            onHide={assignCoachHandleClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Assign coach </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      <h6>
                        User name :{selectedUserToAssignCoach?.firstName}{" "}
                        {selectedUserToAssignCoach?.lastName}
                      </h6>
                    </Form.Label>
                  </Form.Group>{" "}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      <h6>Select coach</h6>
                    </Form.Label>

                    <>
                      <form className="goalSelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) =>
                            setSelectedCoachState(event.target.value)
                          }
                          value={selectedCoachState}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            className="first-options"
                          >
                            Select your coach
                          </option>
                          {allCoaches?.data?.map((coach, index) => {
                            return (
                              <>
                                <option value={coach?._id}>
                                  {coach?.firstName} {coach?.lastName}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                </Form>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={assignCoachHandleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onAssignCoachHandler}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {/* ASSIGN ORGANIZATION MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={showAssignOrganizationModal}
            onHide={assignOrganizationHandleClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Assign Organization </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      <h6>
                        {" "}
                        User name :{
                          selectedUserToAssignOrganization?.firstName
                        }{" "}
                        {selectedUserToAssignOrganization?.lastName}
                      </h6>
                    </Form.Label>
                  </Form.Group>{" "}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      <h6>Select organization</h6>
                    </Form.Label>

                    <>
                      <form className="goalSelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) =>
                            setSelectedOrganizationState(event.target.value)
                          }
                          value={selectedOrganizationState}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            className="first-options"
                          >
                            Select your organization
                          </option>
                          {allOrganizations?.data?.map(
                            (organization, index) => {
                              return (
                                <>
                                  <option value={organization?._id}>
                                    {organization?.organizationName}
                                  </option>
                                </>
                              );
                            }
                          )}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                </Form>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={assignOrganizationHandleClose}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={onAssignOrganizationHandler}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {/* ADD USER MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={addUserModal}
            onHide={onAddUserModalClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Add new user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="first name"
                      onChange={(e) => setNewFirstName(e.target.value)}
                      value={newFirstName}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="last name"
                      onChange={(e) => setNewLastName(e.target.value)}
                      value={newLastName}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>phone Number </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="phone number"
                      onChange={(e) => setNewPhoneNumber(e.target.value)}
                      value={newPhoneNumber}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="email"
                      onChange={(e) => setNewEmail(e.target.value)}
                      value={newEmail}
                    />
                  </Form.Group>
                </Form>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onAddUserModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onAddUserhandler}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {/* EDIT USER MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={editUserModal}
            onHide={onEditUserModalClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Edit user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                {/* <div className="user-pic-name">
      <div className="edit-profile-img">
        {showImage !== null ? (
          <img
            className=" user-profile-img"
            src={showImage}
            alt="img1"
          />
        ) : (
          <>
            {userprofileData?.data?.imageUrl ? (
              <img
                className="user-profile-img"
                src={userprofileData?.data?.imageUrl}
                alt="img"
              />
            ) : (
              <img
                className="user-profile-img"
                src="/img/sample_img_2.png"
                alt="img"
              />
            )}
          </>
        )}

        <div className="image-file">
          <label htmlFor="file-input">
            <img
              className="edit-profile-img-icon"
              src="/img/edit.png"
              alt="img"
            />
          </label>
          <input
            type="file"
            id="file-input"
            onChange={onImageSelect}
          />
        </div>
      </div>
    </div> */}
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="first name"
                      onChange={(e) => setFirstName(e.target.value)}
                      defaultValue={firstName}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="last name"
                      onChange={(e) => setLastName(e.target.value)}
                      defaultValue={lastName}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>phone Number </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="phone number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Form.Group>
                </Form>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onEditUserModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onEditUserhandler}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {/* BLOCK USER LIST MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            size="lg"
            keyboard={false}
            data-dismiss="modal"
            show={blockUserModal}
            onHide={onBlockUserModalClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Blocked list of users</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-unblock-list">
              <>
                {
                  // coachClientsData?.data?.length !== 0
                  blockUsersList?.data?.length ? (
                    <>
                      <table>
                        <thead>
                          <tr
                            className="header-row"
                            style={{ margin: "10px", height: "50px" }}
                          >
                            <th
                              style={{
                                width: "400px",
                                paddingLeft: "6px",
                              }}
                            >
                              Users name
                            </th>

                            <th
                              className="block-user-email"
                              style={{
                                width: "400px",
                              }}
                            >
                              Email
                            </th>
                            <th
                              style={{
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                              }}
                            ></th>
                          </tr>
                        </thead>

                        <tbody>
                          {blockUsersList?.data?.map((data, index) => {
                            return (
                              <tr
                                className="whole-row-client"
                                key={index}
                                style={{
                                  borderBottom: "1px solid #f1f5f9",
                                }}
                              >
                                <td className="pic-name-in-table">
                                  <div className="client-img-in-table ms-3 pt-1">
                                    <img
                                      src={
                                        data?.imageUrl
                                          ? data?.imageUrl
                                          : "/img/sample_img_2.png"
                                      }
                                      className="coach-clients-data-img "
                                      alt="EQWell logo"
                                    />
                                    {/* )} */}
                                  </div>
                                  <div
                                    style={{
                                      paddingTop: "15px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.firstName} {data?.lastName}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    color: "#1b46b4",
                                    width: "500px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.email}
                                </td>
                                <td
                                  style={{
                                    width: "133px",
                                  }}
                                >
                                  <div className="clients-data-btn">
                                    <button
                                      className="btn-unblock-user"
                                      onClick={() => unBlockUserhandler(data)}
                                    >
                                      Unblock
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <h5>No users blocked yet.</h5>
                  )
                }
              </>
            </Modal.Body>
          </Modal>
        </>
        <div className="manage-user">
          <div className="row mb-3 pt-5 pl-5">
            <div className="col-md-6">
              <h1 className="healthKitUpdateHeaderFont d-flex">Manage Users</h1>
            </div>
            <div
              className="col-md-6 d-flex justify-content-end"
              style={{ paddingRight: "65px" }}
            >
              <div>
                <button className="btn-coach me-4" onClick={onAddUserModalShow}>
                  Add new user
                </button>
              </div>
              <div>
                <button
                  className="btn-coach btn-block me-5"
                  onClick={onBlockUserModalShow}
                >
                  Blocked users
                </button>
              </div>
            </div>
          </div>
          <>
            <div className="goal-for-content d-flex ps-5">
              <Form.Label className="d-flex ">
                {/* Filter with organization */}
              </Form.Label>
              <Form.Select
                onChange={(e) => setOrganizationFilter(e.target.value)}
                aria-label="Default select example"
                value={organizationFilter}
              >
                <option value="">Select Organization</option>
                {allOrganizations?.data?.map((organization, index) => {
                  return (
                    <option value={organization?._id}>
                      {organization?.organizationName}
                    </option>
                  );
                })}
              </Form.Select>

              <div className=" ms-2  ">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => setOrganizationFilter("")}
                >
                  Clear
                </button>
              </div>
            </div>
            <div
              className="coach-clients-page "
              style={{ padding: "2% 3% 0% 3%" }}
            >
              <>
                <div className="manage-users-page-main-container">
                  {filteredUsers?.length ? (
                    <>
                      <table>
                        <thead>
                          <tr
                            className="header-row"
                            style={{ margin: "10px", height: "50px" }}
                          >
                            <th
                              style={{
                                width: "600px",
                                paddingLeft: "30px",
                                textAlign: "left",
                              }}
                            >
                              Name
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "350px",
                              }}
                            >
                              Assigned coach
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "250px",
                              }}
                            >
                              Survey
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "250px",
                              }}
                            >
                              Organization
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "250px",
                              }}
                            >
                              Goals
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "300px",
                              }}
                            >
                              Phone number
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "400px",
                              }}
                            >
                              Email
                            </th>
                            <th
                              style={{
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                              }}
                            ></th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredUsers?.map((data, index) => {
                            return (
                              <tr
                                className="whole-row-client"
                                key={index}
                                style={{
                                  borderBottom: "1px solid #f1f5f9",
                                }}
                              >
                                <td className="pic-name-in-table">
                                  <div className="client-img-in-table ms-3 pt-1">
                                    {data?.imageUrl ? (
                                      <img
                                        src={data?.imageUrl}
                                        className="coach-clients-data-img "
                                        alt="EQWell logo"
                                      />
                                    ) : (
                                      <img
                                        src="/img/sample_img_2.png"
                                        className="coach-clients-data-img "
                                        alt="EQWell logo"
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      paddingTop: "15px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.firstName} {data?.lastName}
                                  </div>
                                </td>

                                <td>
                                  <div
                                    className="d-grid gap-2 d-md-block"
                                    style={{
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.coach ? (
                                      <div>
                                        {data.coach?.firstName}{" "}
                                        {data.coach?.lastName}
                                      </div>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        Coach not assigned
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "12px",
                                    // color: "#1b46b4",
                                    width: "160px",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.surveyCompleted ? (
                                    <img
                                      style={{ width: "30px" }}
                                      src="/img/checkmark.png"
                                      alt="Tick Icon"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "30px" }}
                                      src="/img/cross.png"
                                      alt="Cross Icon"
                                    />
                                  )}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "12px",
                                    // color: "#1b46b4",
                                    width: "160px",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.parentOrganization?.organizationName ??
                                    "None"}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "12px",
                                    // color: "#1b46b4",
                                    width: "160px",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.isGoalsSelected ? (
                                    <img
                                      style={{ width: "30px" }}
                                      src="/img/checkmark.png"
                                      alt="Tick Icon"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "30px" }}
                                      src="/img/cross.png"
                                      alt="Cross Icon"
                                    />
                                  )}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "12px",
                                    // color: "#1b46b4",
                                    width: "160px",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.phoneNumber}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "12px",
                                    // color: "#1b46b4",
                                    width: "500px",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.email}
                                </td>
                                <td
                                  style={{
                                    width: "133px",
                                  }}
                                >
                                  <div className="clients-data-btn">
                                    <Dropdown>
                                      <Dropdown.Toggle id="toggle-btn-clients">
                                        <img
                                          src="/img/client-detail-btn.png"
                                          alt="btn-img"
                                          style={{ backgroundColor: "white" }}
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-options-container">
                                        <Dropdown.Item className="dropdown-options">
                                          <NavLink
                                            className="create-activity-font"
                                            to="/coach-activities"
                                            onClick={() => {
                                              onAssignCoach(data);
                                            }}
                                          >
                                            Assign a coach
                                          </NavLink>
                                          <NavLink
                                            className="create-activity-font"
                                            to="/coach-activities"
                                            onClick={() => {
                                              onAssignOrganization(data);
                                            }}
                                          >
                                            Assign an organization
                                          </NavLink>
                                          <NavLink
                                            className="create-activity-font"
                                            to="/coach-activities"
                                            onClick={() => {
                                              recoverUsersStreak(data);
                                            }}
                                          >
                                            Recover users streak
                                          </NavLink>
                                          <NavLink
                                            className="create-activity-font"
                                            to="/coach-activities"
                                            onClick={() => {
                                              onEditUserModalShow(data);
                                            }}
                                          >
                                            Edit
                                          </NavLink>
                                          <NavLink
                                            className="create-activity-font"
                                            to="/coach-activities"
                                            onClick={() => {
                                              blockUserHandler(data);
                                            }}
                                          >
                                            Block
                                          </NavLink>
                                          {data?.surveyCompleted ? (
                                            <NavLink
                                              className="create-activity-font"
                                              to="/coach-activities"
                                              onClick={() => {
                                                onShowSurveyResult(data?._id);
                                              }}
                                            >
                                              Survey results
                                            </NavLink>
                                          ) : null}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div className="No-data-statement">
                      {" "}
                      <h3>No user yet</h3>
                    </div>
                  )}
                </div>
              </>
            </div>
          </>
        </div>
      </>
      <ToastContainer />
    </>
  );
};

export default ManageUsers;
